<template>
  <footer>
    <nav>
      <wiseLogo class="logo" />
      <br /><br /><br />
      <tutkimusLogo class="logo" />
    </nav>
    <nav>
      <router-link to="/tietosuoja">Rekisteri- ja tietosuojaseloste</router-link>
    </nav>
  </footer>
</template>

<style scoped>

  footer {
    display: flex;
    justify-content: space-between;
  }

  footer {
    box-sizing: border-box;
    position: relative;
    padding: 6rem 5rem 5rem;
    background-color: #294634;
    width: 100vw;
    z-index: 1;
    border-top: 1px solid rgba(255,81,0,0.5);
    margin-top: -1px;
  }

  .logo {
    height: 3rem;
    fill: white;
  }

  a {
    color: white;
  }

</style>
