<template>
  <div id='App'>
    <router-view />
  </div>
</template>

<style>
  @import 'fonts/index.css';

  img.layout {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    pointer-events: none;
    opacity: 0.5;
    z-index: 999;
  }

  :root {
    --orange: rgba(255,81,0,0.95); /*#ff5100;*/
    --green: rgb(40, 70, 52); /* #294634; */
    --gray: rgb(200, 200, 200); /* #c8c8c8; */
  }


  #App {
    height: 100%;
  }

  html {
    font-family: 'SimplonMono-Light';
    font-size: 12px;
    /* font-size: 0.94vw; */
    height: 100%;
  }

  @media screen and (max-height: 750px) {
    section.slide {
      font-size: 1vh;
    }
  }

  body {
    background-color: var(--gray);
    color: var(--green);
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: unset;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  h1 {
    box-sizing: border-box;
    font-family: 'SimplonNorm-Light';
    font-weight: lighter;
    color: rgba(255,81,0,0.95);
    font-weight: 100;
    letter-spacing: -0.025em;
    font-size: 1.9em;
    line-height: 0.95em;
    margin: 0;
    margin-bottom: 0.75em;
  }

  h3 {
    color: #ff5100;
    font-weight: lighter;
    text-transform: uppercase;
    font-family: 'SimplonMono-Medium';
    font-weight: normal;
    font-size: 0.85em;
    margin-top: 4rem;
  }

  h4 {
    font-family: 'SimplonMono-Medium';
    margin: 0;
    margin-top: 1em;
    font-weight: normal;
    font-size: 1.2em;
  }

  p a {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid var(--green);
  }

  input {
    font-family: inherit;
  }

  button {
    background-color: transparent;
    border: none;
    box-sizing: border-box;
  }

  p {
    font-size: 1.2em;
    margin: 0;
  }

  p + p {
    margin-top: 1.5em;
  }

  big {
    font-size: 2rem;
    padding: 12rem;
  }

  big p {
    max-width: 24em;
  }

  ol {
    margin-left: 0.25em;
  }

  strong {
    font-weight: inherit;
  }


</style>
