<template>
  <section>
    <scrolllistener v-slot='{ scroll }'>
      <figure>
        <div class='mask' :style='"top: "+ (-100-600*scroll)+"px"'>
          <img :style='"transform: scale("+(0.45 + 0.5*scroll)+")"' src='assets/views/layouts/googlekuva.png'>
        </div>
      </figure>
    </scrolllistener>
  </section>
</template>

<style scoped>
  section {
    display: block;
    position: relative;
    margin: 0 -3.5rem;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
    left: calc(-50vw + 28rem);
    width: 100vw;
    border: 1px solid transparent;
    border-top-color: #ff5100;
    border-bottom-color: #ff5100;
    height: 30rem;
    overflow: hidden;
    box-sizing: border-box;
  }

  figure {
    font-size: 60vh;
    text-align: center;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    height: 100%;
    width: 56rem;
    margin: auto;
    padding: 0;
    box-sizing: border-box;
    margin: auto;
    overflow: hidden;
    background-color: var(--orange);
  }

  figure .mask {
    overflow: hidden;
    display: block;
    height: 44rem;;
    width: 44rem;;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
    background-color: #294634;
  }

  figure .mask img {
    position: absolute;
    left: -100%;
    right: -100%;
    top: -300%;
    bottom: -300%;
    margin: auto;
  }
</style>
